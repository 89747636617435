<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-layout wrap class="odd" px-3 pt-4 mb-4>
        <v-flex xs7 sm7 md3>
          <v-text-field
            v-model="roleName"
            :rules="roleNameRules"
            :label="$t('roles.name')"
            required
            autofocus
          ></v-text-field>
        </v-flex>
        <v-spacer></v-spacer>
        <ConfirmModal @confirm-modal="onRemoveClick">
          <template v-slot:default="{ on }">
            <v-btn v-if="roleId" color="error" v-on="on">
              <fa-icon icon="trash" class="mr-1"></fa-icon>
              {{ $t("global.remove") }}
            </v-btn>
          </template>
        </ConfirmModal>
      </v-layout>
      <v-layout px-3 column>
        <div>
          <v-subheader class="headline">
            {{ $t("roles.restrictions") }}
          </v-subheader>
        </div>
        <div>
          <span v-for="item in restrictions" v-bind:key="item.restrictionId">
            <v-checkbox
              v-model="selectedRestrictions"
              :label="item.restrictionName"
              :value="item.restrictionId"
              color="primary"
              hide-details
            ></v-checkbox>
          </span>
          <div class="v-messages theme--light error--text pt-3 height">
            <div class="v-messages__wrapper">
              <div v-if="!validRestrictions" class="v-messages__message">
                {{ $t("roles.restrictionsRequired") }}
              </div>
            </div>
          </div>
        </div>
      </v-layout>
      <v-layout row>
        <v-spacer></v-spacer>
        <v-btn class="light-button" :to="{ name: 'roles' }">
          {{ $t("global.cancel") }}
        </v-btn>
        <v-btn color="primary" @click="onConfirmClick">
          {{ acceptButtonValue }}
        </v-btn>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import RolesService from "../services/RolesService";
import ConfirmModal from "../components/ConfirmModal";
import { mapState } from "vuex";

export default {
  name: "NewRole",
  components: { ConfirmModal },
  props: ["roleId"],
  data() {
    return {
      selectedRestrictions: [],
      valid: true,
      roleName: null
    };
  },
  mounted() {
    this.$store.dispatch("SET_RESTRICTIONS").then(() => {
      if (this.roleId) {
        this.getRoleDetails();
      }
    });
  },
  computed: {
    ...mapState({ restrictions: state => state.restrictions }),
    validRestrictions() {
      return !!this.selectedRestrictions[0];
    },
    acceptButtonValue() {
      return this.$t(this.roleId ? "global.save" : "global.add");
    },
    roleNameRules() {
      return [
        v =>
          !!v ||
          this.$t("global.required", {
            value: this.$t("roles.name")
          })
      ];
    }
  },
  methods: {
    getRoleDetails() {
      RolesService.GetRoleDetails(this.roleId).then(data => {
        (this.roleName = data.roleName),
          (this.selectedRestrictions = data.restrictions);
      });
    },
    _saveAction() {
      return this.roleId
        ? RolesService.UpdateRole(
            this.roleId,
            this.roleName,
            this.selectedRestrictions
          )
        : RolesService.CreateRole(this.roleName, this.selectedRestrictions);
    },
    onConfirmClick() {
      if (this.$refs.form.validate() && this.validRestrictions) {
        this._saveAction().then(result => {
          if (result.isValid) {
            this.$router.push({ name: "roles" });
          }
        });
      }
    },
    onRemoveClick() {
      RolesService.DeleteRole([this.roleId]).then(result => {
        if (result.isValid) {
          this.$router.push({ name: "roles" });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
// .header {
//     background-color: $secondary;
// }
label {
  font-weight: bold;
}
.v-btn {
  text-transform: none;

  .light-button {
    color: var(--v-primary-base);
  }
}
.v-messages {
  &.height {
    min-height: 28px;
  }
}
</style>
